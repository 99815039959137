// main {
//   margin-left: auto;
//   margin-right: auto;
//   padding: 30px 15px;
//   width: 100%;

//   @media screen and (min-width: 1024px) {
//     max-width: 984px;
//     padding: 30px 0;
//   }

//   @media screen and (min-width: 1440px) {
//     max-width: 1200px;
//   }
// }
.str-chat__date-separator-date {
    font-family: 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}