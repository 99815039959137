@import './src/scss/colors';

button.TwoByTwoLabelsToggle {
  background: #fafafa;
  border: 1px solid white;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0,0,0,0.25);
  height: 42px;
  margin-bottom: 8px;
  width: 2.75em;
  padding: 0.425em 0.75em;
  outline: none;
}

button.TwoByTwoLabelsToggle:active,
button.TwoByTwoLabelsToggle:active:hover svg {
  fill: $black;
}

button.TwoByTwoLabelsToggle:hover {
  cursor: pointer;
}

button.TwoByTwoLabelsToggle:hover svg {
  fill: $pink;
}
