.TwoByTwo {
	background: white;

	&__svg {
		position: relative;
	}

	&__dot:hover {
		cursor: pointer;
		opacity: 1;
	}

	.TwoByTwoLabelsToggle__wrapper {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		z-index: 2000;
	}

	&__AxisLeft, &__AxisBottom {
		&-label {
			font-family: 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
			font-size: 16px;
			text-anchor: middle;
			font-weight: bold;
		}
	}
}
