@import '../../scss/mixins';

// Setup
@import '~stream-chat-react/dist/scss/_variables.scss';

/* fonts */
$main-font: 'Ubuntu', sans-serif;
$second-font: 'Ubuntu', Helvetica, Arial, sans-serif;
$mono-font: Consolas, 'Andale Mono WT', 'Andale Mono', 'Lucida Console',
	'Lucida Sans Typewriter', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono',
	'Liberation Mono', 'Nimbus Mono L', Monaco, 'Courier New', Courier,
	monospace;

$base-font-weight: 400;
$heavy-font-weight: 700;

/* colors */
$primary-color: #08062f;
$secondary-color: #e90a6d;
$secondary-faded: #bcd8ff;

$dt-bg-team: #1d1f22;

@import '~stream-chat-react/dist/scss/base.scss';
// Components
@import 'mml-react/src/styles/index.scss';
@import '~stream-chat-react/dist/scss/vendor/emoji-mart.scss';
@import '~stream-chat-react/dist/scss/ActionsBox.scss';
@import '~stream-chat-react/dist/scss/AttachmentActions.scss';
@import '~stream-chat-react/dist/scss/Attachment.scss';
@import '~stream-chat-react/dist/scss/Avatar.scss';
@import '~stream-chat-react/dist/scss/Audio.scss';
@import '~stream-chat-react/dist/scss/Card.scss';
@import '~stream-chat-react/dist/scss/ChannelHeader.scss';
@import '~stream-chat-react/dist/scss/ChannelList.scss';
@import '~stream-chat-react/dist/scss/ChannelListMessenger.scss';
@import '~stream-chat-react/dist/scss/ChannelListTeam.scss';
@import '~stream-chat-react/dist/scss/ChannelPreview.scss';
@import '~stream-chat-react/dist/scss/ChannelSearch.scss';
@import '~stream-chat-react/dist/scss/ChatDown.scss';
@import '~stream-chat-react/dist/scss/EventComponent.scss';
@import '~stream-chat-react/dist/scss/DateSeparator.scss';
@import '~stream-chat-react/dist/scss/EditMessageForm.scss';
@import '~stream-chat-react/dist/scss/Gallery.scss';
@import '~stream-chat-react/dist/scss/LoadingChannels.scss';
@import '~stream-chat-react/dist/scss/LoadingIndicator.scss';
@import '~stream-chat-react/dist/scss/LoadMoreButton.scss';
@import '~stream-chat-react/dist/scss/Message.scss';
@import '~stream-chat-react/dist/scss/MessageActions.scss';
@import '~stream-chat-react/dist/scss/MessageCommerce.scss';
@import '~stream-chat-react/dist/scss/MessageInput.scss';
@import '~stream-chat-react/dist/scss/MessageInputFlat.scss';
@import '~stream-chat-react/dist/scss/MessageNotification.scss';
@import '~stream-chat-react/dist/scss/MessageList.scss';
@import '~stream-chat-react/dist/scss/MessageTeam.scss';
@import '~stream-chat-react/dist/scss/MessageLivestream.scss';
@import '~stream-chat-react/dist/scss/MessageRepliesCountButton.scss';
@import '~stream-chat-react/dist/scss/Modal.scss';
@import '~stream-chat-react/dist/scss/ModalImage.scss';
@import '~stream-chat-react/dist/scss/NewChannel.scss';
@import '~stream-chat-react/dist/scss/ReactionList.scss';
@import '~stream-chat-react/dist/scss/ReactionSelector.scss';
@import '~stream-chat-react/dist/scss/ReadState.scss';
@import '~stream-chat-react/dist/scss/SendButton.scss';
@import '~stream-chat-react/dist/scss/SimpleReactionsList.scss';
@import '~stream-chat-react/dist/scss/SmallMessageInput.scss';
@import '~stream-chat-react/dist/scss/Thread.scss';
@import '~stream-chat-react/dist/scss/TypingIndicator.scss';
@import '~stream-chat-react/dist/scss/Tooltip.scss';
@import '~stream-chat-react/dist/scss/VirtualMessage.scss';

.str-chat {
	width: 100%;
}

.str-chat__header {
	&-livestream {
		padding: 62px 30px 23px 36px;

		&-left {
			font-size: 18px;

			&--title {
				font-weight: 700;
				margin: 0;
			}

			&--members {
				display: none;
			}
		}
	}
}

.str-chat__date-separator-line {
	background-color: #f1f1f1;
}

.livestream.str-chat {
	.str-chat__list {
		padding: 0 35px;
		@include scrollbar;

		.str-chat__date-separator {
			padding: 10px 0;
		}
	}

	&__message-livestream {
		padding: 18px 0;
		&:hover {
			border: none;
			box-shadow: none;
		}
	}
}

.str-chat__li.str-chat__li--bottom {
	margin: 0;
	.str-chat__message-livestream {
		border-bottom: 0;
	}
}

.str-chat__message-livestream {
	padding: 23px 0 18px 0;
	border-bottom: 1px solid #f1f1f1;
	&:hover {
		border-top-color: transparent;
		border-right-color: transparent;
		border-left-color: transparent;
		box-shadow: none;
		border-radius: 0;
	}

	&-left {
		width: 63px;
		margin-right: 9px;
	}

	&-content {
		padding: 0;

		> * {
			font-size: 15px;
			line-height: 21px;
			font-weight: normal;
			color: #747474;
		}

		p:not(:first-of-type) {
			margin-top: 5px;
		}

		p {
			strong {
				color: $secondary-color;
			}
		}
	}

	&-actions {
		display: flex;
		top: 23px;
		width: auto;
		border: none;
		box-shadow: none;
		z-index: 9;

		time {
			margin-right: 5px;
			color: #a5a5a5;
			font-size: 12px;
			line-height: 12px;
		}
	}

	&-author {
		margin-bottom: 0;
		strong {
			font-size: 14px;
			color: #08062f;
		}
	}
}

.str-chat__input-flat {
	padding: 17px 15px;

	&-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 19px 20px;
		background: #fafafa;
		border-radius: 10px;

		.str-chat__textarea {
			margin: 0 11px;
			> textarea {
				line-height: 21px;
				padding: 3px 0;
				min-height: 17px;
				border-radius: 0;
				background: transparent;
				outline: none;
				color: #747474;

				&:focus {
					box-shadow: none;
				}

				&::placeholder {
					color: #747474;
					opacity: 0.5;
				}
			}
		}
	}
}

.str-chat__emojisearch {
	left: 50%;
	transform: translateX(-50%);
	width: calc(100% - 160px);
	position: absolute;
	background: #ffffff;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.182091),
		inset 0 1px 3px rgba(217, 217, 217, 0.481452);
	border-radius: 10px;

	&__list {
		margin: 0;
		padding: 0;
		list-style-type: none;
		border-radius: 4px 4px 0 0;
	}
}

.str-chat__message-mention {
	color: $secondary-color;
}
