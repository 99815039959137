@import 'colors';

@mixin scrollbar() {
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: $grey;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
  }
}
