.BarGraph {
	&__svg {
		position: relative;
		z-index: 1000;
	}

	&__bar {
		cursor: pointer;
	}

	&__legend {
		background: white;
		justify-content: center;
		font-family: 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
		z-index: 1000;

		&-contents {
			display: flex;
			flex-direction: row;
			justify-content: center;
			order: 1;
		}

		&-item {
			margin: 0 0.5em 0 0;

			&:hover {
				cursor: pointer;
			}
		}

		&-label {
			padding: 0 0.5em;
		}
	}

	&__AxisLeft, &__AxisBottom {
		&-label {
			font-family: 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
			font-size: 16px;
			text-anchor: middle;
			font-weight: bold;
		}
	}
}
