.LineGraph {
	display: flex;
	position: relative;

	&__legend {
		font-family: 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

		&-item {
			margin: 0 0 0.5em 0;

			&:hover {
				cursor: pointer;
			}
		}

		&-label {
			padding-left: 0.5em;
		}
	}

	&__line-hover-target {
		z-index: 1000;
	}

	&__AxisLeft, &__AxisBottom {
		&-label {
			font-family: 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
			font-size: 16px;
			text-anchor: middle;
			font-weight: bold;
		}
	}
}
