.Annotation {
  position: absolute;
  transform-style: preserve-3d;
  width: 400px;
  z-index: 2000;
}

.AnnotationContent {
  transform-style: preserve-3d;
}

.Annotation:before {
  background: white;
  content: '';
  display: block;
  height: 1em;
  left: -0.5em;
  position: absolute;
  top: 32px;
  transform: rotateZ(45deg) translateZ(0.1px);
  transform-origin: center center;
  width: 1em;
}

.Annotation:after {
  background: white;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.25);
  content: '';
  display: block;
  height: 1em;
  left: -0.5em;
  position: absolute;
  top: 32px;
  transform: rotateZ(45deg) translateZ(-0.1px);
  transform-origin: center center;
  width: 1em;
}

.Annotation.Annotation--is-left-positioned:before {
  left: 100%;
  transform: translateX(-50%) rotateZ(45deg) translateZ(0.1px);
}

.Annotation.Annotation--is-left-positioned:after {
  left: 100%;
  transform: translateX(-50%) rotateZ(45deg) translateZ(-0.1px);
}
