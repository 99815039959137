@import './src/scss/colors';

.Home__chart-list-item {
  margin-bottom: 1em;
}

.Home__chart-list-item a {
  text-decoration: none;
}

.Home__links-subsection {
  margin-bottom: 1.5em;
}

.Home__links-subtitle {
  color: $pink;
  margin-bottom: 0.5em;
}

.Home__chart-list-item-title {
  border-bottom: 1px solid #ddd;
  color: $black;
  display: inline-block;
  font-family: "Lora", "Times New Roman", "Times", serif;
  font-weight: 500;
  text-decoration: none;
}

.error {
  color: $pink;
}