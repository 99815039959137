@import "./scss/reset";
@import "./scss/colors";
@import "./scss/typography";
@import "./scss/grid";
@import "./scss/main";
@import "./scss/table";

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: $grey_color_01;
  margin: 0;
}

a,
a:visited {
  color: #247bdf;
}

.anchor-target::before {
  content: "";
  display: block;
  height: 84px;
  margin-top: -84px;
  visibility: hidden;
}
