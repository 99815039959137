.TwoByTwoBipartite {
	background: white;

	.TwoByTwoLabelsToggle__wrapper {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		z-index: 2000;
	}

	&__svg {
		display: flex;
		flex-direction: column;
		position: relative;
	}

	&__dot:hover {
		cursor: pointer;
		opacity: 1;
	}

	&__legend {
		justify-content: center;
		font-family: "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
		z-index: 1000;

		&-contents {
			display: flex;
			flex-direction: row;
			justify-content: center;
			order: 1;
		}

		&-item {
			margin: 0 0.5em 0 0;
		}

		&-item:hover {
			cursor: pointer;
		}

		&-label {
			padding: 0 0.5em;
		}
	}

	&__line-hover-target {
		z-index: 1000;
	}

	&__AxisLeft, &__AxisBottom {
		&-label {
			font-family: 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
			font-size: 16px;
			text-anchor: middle;
			font-weight: bold;
		}
	}
}
