$table-border-radius: 8px;

table {
  background: white;
  border-collapse: collapse;
  border-radius: $table-border-radius;
  overflow: hidden;
  text-align: left;
  width: 100%;
}

td,
th {
  padding: 15px;
}

thead {
  background: linear-gradient(180deg, white, white, #f9f9f9);
  background-size: contain;
  border-bottom: 2px solid #eee;
  border-top-left-radius: $table-border-radius;
  border-top-right-radius: $table-border-radius;
}
