$primary: #011442;
$secondary: #FF1B97;
$link: #2D99FF;
$gray: rgba(242, 242, 242, 0.628688);
$shadow: rgba(0, 0, 0, 0.243198);
$fontFamily: 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

.MediaMentionModal {
	background: $gray;
	box-shadow: 0 2px 15px $shadow;
	border-radius: 6px;
	color: $primary;
	font-family: $fontFamily;
	font-size: 14px;
	line-height: 22px;
	width: 500px;

	& p {
		margin-bottom: 0;
	}

	&__header {
		padding: 30px;

		&__term {
			margin: 0;
		}

		&__num-mentions {
			color: $primary;
			font-size: 13px;
			line-height: 15px;
			margin-top: 7px;
		}

		&__details {
			color: $primary;
			font-size: 13px;
			line-height: 17px;
			margin-top: 7px;
		}

		&__insights {
			background: transparent;
			margin-top: 15px;

			&-title {
				color: $secondary;
				margin-bottom: 0.25em;
				margin-top: 0;
				text-transform: uppercase;
			}

			&-content {
				font-family: $fontFamily;
				font-weight: 300;
				font-size: 15px;
				line-height: 24px;
			}
		}
	}

	&__media-mentions {
		background: white;
		padding: 30px 30px 0 30px;
		max-height: 200px;
		overflow-y: scroll;

		&-title {
			margin-bottom: -1em;
			margin-top: 0;
			text-transform: uppercase;
		}

		&-list {
			list-style: none;
			margin: 0;
			padding: 0 0 30px !important;
		}

		&-item-publisher-name {
			margin-bottom: 0.25em;
			text-transform: uppercase;
			font-family: $fontFamily;
			font-weight: bold;
			font-size: 12px;
			line-height: 24px;
			color: $primary;

			a {
				color: $link;
				text-decoration: none;
			}
		}

		&-item-content {
			font-style: italic;
		}
	}
}
