$black: #0a142b;
$softblack: #08062f;
$blue: #1b2541;
$sky: #6ed1e7;
$sea: #5bc0be;
$brightsea: #00ffe3;
$darksea: #399CB2;
$pink: #e90a6d;
$grey: #d9d9d9;
$grey_color_01: #f5f3f2;
$lightgrey: #fafafa;
$text: #4F4B4B;
$midgrey: #929292;
$darkgrey: #979797;
$red: #ef3219;
$green: #1fa51b;

$input-status-primary-border-color: #3366ff;
$input-status-primary-text-color: #000000;
$input-status-success-border-color: #00e58f;
$input-status-success-text-color: #3ee90a;
$input-status-warning-border-color: #ffaa00;
$input-status-warning-text-color: #ffaa00;
$input-status-danger-border-color: #ff116e;
$input-status-danger-text-color: #e9350a;
$input-status-info-border-color: #0095ff;
$input-status-info-text-color: #3123ae;
$input-status-error-border-color: #e9350a;
$input-status-error-text-color: #e9350a;
