.Table {
  line-height: 1.2em;
  table-layout: fixed;
  white-space: nowrap;

  thead {
    background: none;
    border-bottom: none;

    tr {
      height: 3em;

      th {
        font-variant: small-caps;
        font-weight: bold;

        .Table__sort-indicator {
          position: absolute;
          right: 0.5em;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  th, td {
    padding: 0.5em 1em;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th {
    padding-right: 2em;
    position: relative;
  }

  tbody {
    tr {
      td {
        &:first-child {
          font-weight: bold;
        }

        &:not(:first-child) {
          color: #747474;
        }
      }
    }
  }
}
